.container {
    @apply py-12 px-4 sm:px-6 lg:px-8;

    /* background: #fff url("/images/landing-bckgd@2x.png") no-repeat center top; */
}

.externalLinkPlain {
    @apply text-cleanSlate text-center text-[14px];
}

.externalLinkLink {
    @apply text-progressBarBlue;
}

.divider {
    @apply mt-6 border-lightGray block;
}

.googleContainer {
    @apply flex items-center justify-center min-h-16 mb-3;
}

.googleContent {
    @apply text-center text-sm leading-9 font-bold text-progressBarBlue;
}

.helpContainer {
    @apply flex items-center justify-center text-[12px];
}

.helpMessage {
    @apply mt-0 text-center text-xs leading-9 text-progressBarBlue;
}

.helpLink {
    @apply underline;
}

.signUpInsteadHdr {
    @apply block border-t mt-2 mb-0 py-8 pb-2 text-[14px];
}

.error {
    @apply bg-solidRed/10 border-[1px] border-solidRed/30 text-left rounded-lg p-[12px] text-cleanSlate text-[14px] leading-normal font-normal mt-2;
}

.errorLink {
    @apply underline;
}

.emailContainer {
    @apply flex items-center justify-center min-h-16 mb-3 mt-6;
}

.emailContent {
    @apply text-center text-sm leading-9 font-bold text-progressBarBlue;
}

.emailInput {
    @apply mb-2;
}

.magicLinkForm input {
    height: 42px;
}
