.backgroundContainer {
    @apply py-[20px];
}

.outerContainer {
    @apply h-full py-2 px-4 -mr-4 -ml-4 sm:px-6 lg:px-8;
}

.middleContainer {
    @apply min-h-full flex items-center justify-center;
}

.innerContainerNarrow {
    @apply bg-white w-full md:w-6/12 lg:w-5/12 xl:w-4/12 p-4 sm:p-8 shadow-xl rounded-lg text-center;
}

.innerContainerMedium {
    @apply bg-white w-full md:w-9/12 lg:w-8/12 xl:w-7/12 p-8 shadow-xl border-2 text-center;
}

.innerContainerWide {
    @apply bg-white w-full md:w-11/12 lg:w-10/12 xl:w-9/12 2xl:w-8/12 p-8 pb-0 shadow-xl border-2;
}

.headerContainer {
    @apply mb-2;
}

.headerContent {
    @apply text-center text-[24px] leading-7 text-cleanSlate font-bold object-center;
}

.headerContentLeft {
    @apply flex items-center text-[24px] leading-7 text-cleanSlate font-bold object-center;
}

.headerContent img {
    @apply object-center content-center inline-block mb-3;
}

.divider {
    @apply mt-[25px] mb-[25px] border-lightGray;

    display: block !important;
}

.contentContainer {
    @apply flex flex-wrap md:flex-nowrap pb-8 gap-4;
}

.contentDivider {
    @apply flex-shrink hidden md:block;

    min-width: 1px;
    max-width: 1px;
    background-color: var(--color-gray);
    opacity: 1;
}

.sidebarContent {
    @apply flex-auto border-t-2 mt-4 py-4 md:border-0 md:justify-end justify-center;
}

.sidebarContent img {
    @apply w-1/2 md:w-auto items-center justify-center;
}
