.errorMessage {
    @apply text-red-600 bg-red-200 py-2 px-4 rounded-md;
}

.errorHeader {
    @apply sm:text-lg text-red-600 pb-4;
}

.onboardingHeaderLeft {
    @apply font-instrument font-semibold text-xl text-cleanSlate;
}

.gettingStartedInternalContainer {
    @apply flex flex-col w-full bg-white p-6 shadow-md rounded-md border border-lightGray;
}

.gettingStartedImageExternalContainer {
    @apply h-[210px] sm:h-full w-full flex;
}

.gettingStartedImageInternalContainer {
    @apply h-full w-full flex items-center justify-center bg-white;
}

.internalLink {
    @apply text-progressBarBlue cursor-pointer;
}

.authorizeAccountDescription {
    @apply py-4 text-cleanSlate text-base font-instrument;
}

.authorizeAccountDescription p {
    @apply pb-6 text-base font-instrument text-cleanSlate;
}

.description {
    @apply flex justify-center text-center text-gray-500 leading-5;
}

.oauthButtonContainer {
    @apply mt-2;
    @apply flex sm:block justify-center text-center
}

.oauthButton {
    @apply p-0 -ml-1;
    @apply flex justify-center;
}

/* A container that mimics the look of the sign-in and log-in screens instead of using the Panel element */

.link {
    @apply text-progressBarBlue cursor-pointer;
}

/* Extra Features screen */

.avatar img {
    @apply h-20 w-20 rounded-full mx-auto mt-5;
}

.button {
    @apply rounded-lg text-xs py-3 px-6 mr-0;
}
